import React, { FC } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { useTranslation } from '../../hooks/UserTranslation'
import { RESOURCE_IMAGE_EN_FLAG, RESOURCE_IMAGE_VN_FLAG } from '../../utilities/Constant'
import { languageCode2Name } from '../../utilities/common'
import { Dropdown } from '../share/ANTD/Dropdown'
import { Menu } from '../share/ANTD/Menu'
import { Tooltip } from '../share/ANTD/Tooltip'
import { useAsyncAction } from '../../hooks/useAsyncAction'
import { API_URL_ACCOUNT } from '../../utilities/APIConstant'
import Client from '../../utilities/Client'
import { reloadUserData } from '../../store/AccountData'
import { useJWTIsLogin } from '../../hooks/JWTHooks'

const languageNumberMap = {
  en: 0,
  vi: 1,
  vn: 1
}
export const useChangeCandidateLanguageAsync = (): [(lang: string) => Promise<any>, boolean] => {
  const { changeLanguage } = useTranslation()
  const isLogin = useJWTIsLogin()
  const [changeLanguageAsync, changingLanguage] = useAsyncAction((lang: string) => {
    if (!isLogin) {
      changeLanguage(lang)
      return Promise.resolve({})
    }
    return Client.put(API_URL_ACCOUNT, {
      data: {
        language: languageNumberMap[lang]
      }
    }).then((rs) => {
      changeLanguage(lang)
      return rs
    })
  })
  return [changeLanguageAsync, changingLanguage]
}
const ChangeLanguageDropdown: FC<{
  className?: string
  trigger?: Array<any>
  configTooltip?: { show?: boolean; title?: string; placement?: any }
}> = ({ trigger = ['hover', 'click'], configTooltip = { show: false, title: '', placement: 'top' }, className }) => {
  const { currentLanguage, translate } = useTranslation()
  const [changeLanguageAsync] = useChangeCandidateLanguageAsync()
  return (
    <div className={`d-flex ${className}`}>
      <style jsx>{`
        .profile-drop-down-link {
          display: flex;
          align-items: center;
          color: #595959;
          font-weight: bold;
        }
        .profile-header-name {
          margin-right: 3px;
        }
        img.lang-flag {
          height: 20px;
          margin-right: 5px;
        }
      `}</style>
      <Dropdown
        trigger={trigger}
        overlay={
          <Menu>
            <Menu.Item
              onClick={() => {
                changeLanguageAsync('vi').then(() => reloadUserData())
              }}>
              <div className="d-flex align-items-center">
                <img className="lang-flag" src={RESOURCE_IMAGE_VN_FLAG} alt="vn_flag" />
                <span>{translate('label:language_vn')}</span>
              </div>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                changeLanguageAsync('en').then(() => reloadUserData())
              }}>
              <div className="d-flex align-items-center">
                <img className="lang-flag" src={RESOURCE_IMAGE_EN_FLAG} alt="en_flag" />
                <span>{translate('label:language_en')}</span>
              </div>
            </Menu.Item>
          </Menu>
        }>
        {configTooltip.show ? (
          <Tooltip placement={configTooltip.placement} title={configTooltip.title}>
            <a className="ant-dropdown-link profile-drop-down-link" onClick={(e) => e.stopPropagation()}>
              {currentLanguage && currentLanguage === 'vi' ? (
                <img className="lang-flag" src={RESOURCE_IMAGE_VN_FLAG} alt="vn_flag" />
              ) : (
                <img className="lang-flag" src={RESOURCE_IMAGE_EN_FLAG} alt="en_flag" />
              )}
              <span className="profile-header-name">{languageCode2Name(currentLanguage)}</span> <DownOutlined />
            </a>
          </Tooltip>
        ) : (
          <a className="ant-dropdown-link profile-drop-down-link" onClick={(e) => e.stopPropagation()}>
            {currentLanguage && currentLanguage === 'vi' ? (
              <img className="lang-flag" src={RESOURCE_IMAGE_VN_FLAG} alt="vn_flag" />
            ) : (
              <img className="lang-flag" src={RESOURCE_IMAGE_EN_FLAG} alt="en_flag" />
            )}
            <span className="profile-header-name">{languageCode2Name(currentLanguage)}</span> <DownOutlined />
          </a>
        )}
      </Dropdown>
    </div>
  )
}

export default ChangeLanguageDropdown
